import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "../tools/withStyles";
import { Link } from "../components/Link";
import { Main } from "../components/Main";
import { Text } from "../components/Text";
import { Fader } from "../components/Fader";
import { Secuence } from "../components/Secuence";

const styles = (theme) => ({
  root: {},
});

class About extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
  };

  render() {
    const { classes } = this.props;

    return (
      <Main className={classes.root}>
        <article>
          <Secuence stagger>
            <header>
              <h1>
                <Text>About Me</Text>
              </h1>
            </header>
            <center>
              <Fader>
                <img
                  width="250px"
                  src="https://storage.natunakode.com/foto-triandi.png"
                  alt="Triandi"
                />
              </Fader>
              <p>
                Author at blog <Link href='https://natunakode.com' target='facebook'>
              <Text>natunakode.com</Text>
            </Link>, and I am also a member of the Dicoding Elite Role <Link href='https://www.dicoding.com/users/triandi' target='facebook'>
              <Text>Discussion Squad</Text>
            </Link>. You can visit my bio at <Link href='https://bio.triandi.natunakode.com/' target='facebook'>
              <Text>bio.triandi.natunakode.com</Text>
            </Link>.
              </p>
            </center>
          </Secuence>
        </article>
      </Main>
    );
  }
}

export default withStyles(styles)(About);
